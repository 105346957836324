@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic");
@import "~@fortawesome/fontawesome-free/css/all.css";
// OverlayScrollbars
@import "~overlayscrollbars/css/OverlayScrollbars.css";
// iCheck
@import "~icheck-bootstrap/icheck-bootstrap.css";
// AdminLTE
@import "../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css";

@import "~datatables.net-bs4/css/dataTables.bootstrap4";
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4";

// @tailwind base;
// @tailwind components;
// @tailwind utilities;

.ui-sortable-handle{
  cursor: move;
  margin-bottom: 20px;
}


.dark-mode a:not(.btn) {
  color: #c1e2e8 !important;
}
.dark-mode a:not(.btn):hover {
  color: #3da2b7 !important;
}

.dataTables_filter {
  padding-right: 0.5rem !important;
}
.dataTables_paginate {
  padding-right: 0.5rem !important;
}
.dataTables_length {
  padding-left: 1rem !important;
}
.dataTables_info {
  padding-left: 1rem !important;
}

.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  margin: auto;
  background-color: #ccc!important;
  &--success {
    background-color: #28a745!important;
  }
  &--danger {
    background-color: #dc3545!important;
  }
}
